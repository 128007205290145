import React from "react";
import style from "./colorPicker.module.css";
import { DropdownButton } from "../toolbar-buttons";
import { ColorOpacityPicker, OutlineColor, SolidColor } from "frontend/ui-components/color-opacity-picker";
import { replaceColorOpacity } from "frontend/utils/color-utils";
import classNames from "classnames";

export function ColorPicker({
  value,
  onChange,
  multi,
  colorPalette,
  customColors,
  withTransparency = false,
  tooltip,
  onlyOutline = false,
  allowCustomColor = false,
  isSplitCell = false,
  theme = "dark",
  boundary = "no-controls-area",
  editable = true,
}: {
  value?: string;
  onChange?: (color: string, addUndo: boolean) => void;
  multi?: boolean;
  colorPalette?: any;
  customColors?: any;
  withTransparency?: boolean;
  tooltip?: string;
  onlyOutline?: boolean;
  allowCustomColor?: boolean;
  isSplitCell?: boolean;
  theme?: "dark" | "light";
  boundary?: string;
  editable?: boolean;
}) {
  function renderColorPalette() {
    return (
      <ColorOpacityPicker
        value={value}
        onChange={(c, addUndo) => {
          if (typeof c == "number") {
            c = replaceColorOpacity(value, c);
          }
          onChange?.(c, addUndo);
        }}
        colorPalette={colorPalette}
        customColors={customColors}
        withTransparency={withTransparency}
        allowCustomColor={allowCustomColor}
        isSplitCell={isSplitCell}
      />
    );
  }

  const iconColor = multi
    ? "conic-gradient(#ff4800, #dfd902, #20dc68, #0092f4, #da54d8, #ff4800)"
    : value || "transparent";

  return (
    <DropdownButton
      tooltip={tooltip ?? "Change color"}
      renderPopup={() => renderColorPalette()}
      data-testid={onlyOutline ? "strokecolorpicker" : "FloatingColorPicker"}
      className={classNames(style.colorPopup, style[theme])}
      boundary={boundary}
      editable={editable}
    >
      {onlyOutline ? <OutlineColor color={iconColor} /> : <SolidColor color={iconColor} />}
    </DropdownButton>
  );
}
